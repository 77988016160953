import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './checkbox.scss'

// TODO: Add disabled state (https://codepen.io/Vestride/pen/dABHx)

class Checkbox extends Component {
    render() {
        const { name, className } = this.props

        return (
            <div className={`form-field checkbox ${className}`}>
                <input type="checkbox" name={name} id={name}/><label className="form-field-label" htmlFor={name}>{this.props.children}</label>
            </div>
        )
    }
}

Checkbox.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.string,
}

export default Checkbox
