import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './button.scss'

class Button extends Component {
    render() {
        const { to, className, disabled, id, target } = this.props

        const internal = (/^\/(?!\/)/).test(to)

        if (internal) {
            return (
                <Link className="has-button" to={to} id={id} target={target}>
                    <button disabled={disabled} className={`button ${className}`}>{this.props.children}</button>
                </Link>
            )
        }

        return (
            <a className="has-button" href={to} id={id} target={target}>
                <button disabled={disabled} className={`button ${className}`}>{this.props.children}</button>
            </a>
        )
    }
}

Button.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    target: PropTypes.element,
    children: PropTypes.string,
}

export default Button
