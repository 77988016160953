import Button from '../components/button'
import Checkbox from '../components/checkbox'
import Icon from '../components/icon'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'

import './style-guide.scss'
import '../components/radio.scss'

const StyleGuide = () => (
    <Layout>
        <SEO title="Style Guide" />
        <h1>Welcome to the Style Guide</h1>
        <section className="style-guide-section">
            <header className="style-guide-section-header">
                <h3 className="style-guide-section-title">Typography</h3>
            </header>
            <h1 className="text-center">Desktop</h1>
            <h1 className="text-center"><span className="weight-bold">Raleway Bold</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="weight-medium">Raleway Medium</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="weight-regular">Raleway Regular</span></h1>
            <div className="font-items">
                <div className="font-item">
                    <div className="font-example">
                        <h1>H1</h1>
                    </div>
                    <div className="font-name">Raleway Bold</div>
                    <div className="font-info">FS - 50px / LH - 60px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <h2>H2</h2>
                    </div>
                    <div className="font-name">Raleway Bold</div>
                    <div className="font-info">FS - 40px / LH - 52px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <h3>H3</h3>
                    </div>
                    <div className="font-name">Raleway Bold</div>
                    <div className="font-info">FS - 26px / LH - 34px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <h4>H4</h4>
                    </div>
                    <div className="font-name">Raleway Bold</div>
                    <div className="font-info">FS - 22px / LH - 28px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <h5>H5</h5>
                    </div>
                    <div className="font-name">Raleway Bold</div>
                    <div className="font-info">FS - 20px / LH - 28px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <h6>H6</h6>
                    </div>
                    <div className="font-name">Raleway Bold</div>
                    <div className="font-info">FS - 18px / LH - 26px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <div className="button">Button</div>
                    </div>
                    <div className="font-name">Raleway Regular</div>
                    <div className="font-info">FS - 18px / LH - 18px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <a href="/">Link</a>
                    </div>
                    <div className="font-name">Raleway Regular</div>
                    <div className="font-info">FS - 18px / LH - 18px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <p>Paragraph</p>
                    </div>
                    <div className="font-name">Raleway Regular</div>
                    <div className="font-info">FS - 18px / LH - 28px</div>
                </div>
                <div className="font-item">
                    <div className="font-example">
                        <p><small>Small Text</small></p>
                    </div>
                    <div className="font-name">Raleway Regular</div>
                    <div className="font-info">FS - 16px / LH - 24px</div>
                </div>
            </div>
        </section>
        <section className="style-guide-section">
            <header className="style-guide-section-header">
                <h3 className="style-guide-section-title">Colours</h3>
            </header>
            <div className="groups">
                <div className="group">
                    <h4 className="label">Primary Colours</h4>
                    <div className="swatch-items">
                        <div className="swatch-item">
                            <div className="swatch primary"/>
                            <div className="swatch-hex">#F79423</div>
                        </div>
                        <div className="swatch-item">
                            <div className="swatch secondary"/>
                            <div className="swatch-hex">#003A5D</div>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <h4 className="label">Tertiary Colours</h4>
                    <div className="swatch-items">
                        <div className="swatch-item">
                            <div className="swatch shade-5"/>
                            <div className="swatch-hex">#151517</div>
                        </div>
                        <div className="swatch-item">
                            <div className="swatch shade-4"/>
                            <div className="swatch-hex">#676767</div>
                        </div>
                        <div className="swatch-item">
                            <div className="swatch shade-3"/>
                            <div className="swatch-hex">#E8E8E8</div>
                        </div>
                        <div className="swatch-item">
                            <div className="swatch shade-2"/>
                            <div className="swatch-hex">#F1F1F1</div>
                        </div>
                        <div className="swatch-item">
                            <div className="swatch shade-1"/>
                            <div className="swatch-hex">#FFFFFF</div>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <h4 className="label">Secondary Colours</h4>
                    <div className="swatch-items">
                        <div className="swatch-item">
                            <div className="swatch tertiary"/>
                            <div className="swatch-hex">#70A7BE</div>
                        </div>
                        <div className="swatch-item">
                            <div className="swatch quaternary"/>
                            <div className="swatch-hex">#57585C</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="style-guide-section">
            <header className="style-guide-section-header">
                <h3 className="style-guide-section-title">Text</h3>
                <div className="medium-width">
                    <p>International research shows the pivotal importance of good strategic leadership for both improved school performance and student outcomes.
                        The Strategic Leadership for Principals <strong>Programme is an action-orientated curriculum which helps principals build robust
                        strategic</strong> plans for their school. Facilitated by senior business leaders and supported by skilled <a href="/">capacity partners, the
                        programme</a> is also designed to support each principal in their leadership development. Programme content is frequently updated by subject
                        matter experts and tailored to meet the needs of individual participants by skilled facilitators.</p>
                    <h4>Programme structure</h4>
                    <p>In the Strategic Leadership for Principals Programme, we work intensively with cohorts of six principals over 10 months to develop strategic
                        plans for their schools.</p>
                    <p>Senior business leaders facilitate a series of workshops in which principals learn about strategic planning in an education setting.</p>
                </div>
            </header>
        </section>
        <section className="style-guide-section">
            <header className="style-guide-section-header">
                <h3 className="style-guide-section-title">List</h3>
            </header>
            <div className="medium-width">
                <h4>Unordered</h4>
                <ul>
                    <li>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</li>
                    <li>Vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio </li>
                    <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                </ul>
                <h4>Ordered</h4>
                <ol>
                    <li>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</li>
                    <li>Vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio </li>
                    <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                </ol>
            </div>
        </section>
        <section className="style-guide-section">
            <header className="style-guide-section-header">
                <h3 className="style-guide-section-title">Style</h3>
            </header>
            <h4 className="label">Corner radius (5)</h4>
            <div className="border-radius"/>
        </section>
        <section className="style-guide-section">
            <header className="style-guide-section-header">
                <h3 className="style-guide-section-title">Components</h3>
            </header>
            <div className="groups">
                <div className="group">
                    <h4 className="label">Buttons</h4>
                    <div className="item-group-wrapper">
                        <div className="item-group">
                            <p>Default</p>
                            <Button to="/" className="primary">Get Started</Button>
                        </div>
                        <div className="item-group">
                            <p>Secondary</p>
                            <Button to="/" className="secondary">Get Started</Button>
                        </div>
                        <div className="item-group">
                            <p>Icon</p>
                            <Button to="/" className="secondary"><Icon name="download" title="download"/>Get Started</Button>
                        </div>
                    </div>
                    <div className="item-group-wrapper">
                        <div className="item-group">
                            <p>Hover</p>
                            <Button to="/" className="primary hover">Get Started</Button>
                        </div>
                        <div className="item-group">
                            <Button to="/" className="secondary hover">Get Started</Button>
                        </div>
                        <div className="item-group">
                            <Button to="/" className="secondary hover"><Icon name="download" title="download"/>Get Started</Button>
                        </div>
                    </div>
                    <div className="item-group-wrapper">
                        <div className="item-group">
                            <p>Disabled</p>
                            <Button to="/" disabled={true} className="primary disabled">Get Started</Button>
                        </div>
                        <div className="item-group">
                            <Button to="/" disabled={true} className="secondary disabled">Get Started</Button>
                        </div>
                        <div className="item-group">
                            <Button to="/" disabled={true} className="secondary disabled"><Icon name="download" title="download"/>Get Started</Button>
                        </div>
                    </div>
                </div>
                <div className="group">
                    <h4 className="label">Checkbox</h4>
                    <div className="item-group-wrapper">
                        <div className="item-group">
                            <p>Default</p>
                            <Checkbox name="case-studies">Case Studies</Checkbox>
                        </div>
                        <div className="item-group">
                            <p>Hover</p>
                            <Checkbox className="hover" name="resources">Resources</Checkbox>
                        </div>
                        <div className="item-group">
                            <p>Active</p>
                            <Checkbox className="checked" name="news">News</Checkbox>
                        </div>
                    </div>
                    <div className="spacer"/>
                    <h4 className="label">Radio Buttons</h4>
                    <div className="item-group-wrapper">
                        <div className="item-group">
                            <p>Default</p>
                            <div className="form-field radio">
                                <input type="radio" name="2018" id="2018" value="2018"/><label className="form-field-label" htmlFor="2018">2018</label>
                            </div>
                        </div>
                        <div className="item-group">
                            <p>Hover</p>
                            <div className="form-field radio hover">
                                <input type="radio" name="2019" id="2019" value="2019"/><label className="form-field-label" htmlFor="2019">2019</label>
                            </div>
                        </div>
                        <div className="item-group">
                            <p>Active</p>
                            <div className="form-field radio checked">
                                <input type="radio" name="2020" id="2020" value="2020"/><label className="form-field-label" htmlFor="2020">2020</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default StyleGuide
